import api from './api';
import axios from 'axios';

interface CreateUserParams {
  username: string;
  email: string;
  password: string;
  role: string;
}

export const createUser = async (params: CreateUserParams) => {
  const response = await api.post('/users', params);
  return response.data;
};

export const getUserRole = async (username: string): Promise<string> => {
  try {
    console.log(`Fetching role for user: ${username}`);
    const response = await api.get(`/users/${username}/role`);
    console.log('User role response:', response.data);
    
    if (!response.data || !response.data.role) {
      throw new Error('Invalid response format');
    }
    
    return response.data.role;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Detailed error:', error.response?.data);
      if (error.response?.status === 502) {
        throw new Error('Service temporarily unavailable. Please try again later.');
      }
      if (error.response?.status === 403 || error.response?.status === 401) {
        throw new Error('Please login again.');
      }
      if (error.response?.status === 404) {
        throw new Error('User role not found. Please contact support.');
      }
    }
    throw new Error('Unable to fetch user role. Please try again later.');
  }
};
