import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn, fetchAuthSession, getCurrentUser, signOut } from 'aws-amplify/auth';
import InputField from '../../components/InputField';
import Button from '../../components/Button';
import { StyledTitle } from './styles';
import { styled } from 'styled-components';
import { fadeIn } from '../../styles/animations';
import { useAuth } from '../../context/AuthProvider';
import { getUserRole } from '../../services/userService';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #202123;
`;

const BoxContainer = styled.div`
  padding: 30px 80px;
  background-color: #171819;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 1.5s ease-out;
`;

const Quote = styled.p`
  color: #ACA59A;
  margin: 16px 0px;
`;

const Footer = styled.footer`
  width: 100%;
  padding: 20px 0;
  color: #5f5f5f;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
`;

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const { refreshUser } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);

  const checkCurrentUser = useCallback(async () => {
    try {
      const currentUser = await getCurrentUser();
      if (currentUser) {
        // User is already signed in, redirect to main page
        navigate('/');
      }
    } catch (error) {
      // No current user, stay on login page
      console.log('No current user');
    }
  }, [navigate]);

  useEffect(() => {
    checkCurrentUser();
  }, [checkCurrentUser]);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(null); // Clear any previous errors
    
    try {
      // First check if user is already signed in
      try {
        const currentSession = await fetchAuthSession();
        if (currentSession.tokens?.idToken) {
          console.log('User already has valid session, proceeding with role fetch');
          await handleSuccessfulLogin();
          return;
        }
      } catch (sessionError) {
        // No valid session, proceed with login
        console.log('No existing session, proceeding with login');
      }

      // Attempt sign in
      const { isSignedIn } = await signIn({ username, password });
      if (isSignedIn) {
        await handleSuccessfulLogin();
      }
    } catch (error: any) {
      if (error.name === 'UserAlreadyAuthenticatedException') {
        console.log('User already authenticated, proceeding with role fetch');
        await handleSuccessfulLogin();
      } else {
        console.error('Error during sign in:', error);
        setError('Invalid username or password');
      }
    }
  };

  const handleSuccessfulLogin = async () => {
    try {
      // Get the session first
      const session = await fetchAuthSession();
      const token = session.tokens?.idToken?.toString();
      
      if (!token) {
        throw new Error('No token received after login');
      }
      
      // Set token in localStorage and in api headers
      localStorage.setItem('token', token);
      
      // Get current user
      const currentUser = await getCurrentUser();
      if (!currentUser?.username) {
        throw new Error('Unable to get current user information');
      }

      try {
        // Fetch user role - add retry logic
        let retries = 3;
        let role = null;
        
        while (retries > 0) {
          try {
            role = await getUserRole(currentUser.username);
            break;
          } catch (error) {
            retries--;
            if (retries === 0) throw error;
            // Wait 1 second before retrying
            await new Promise(resolve => setTimeout(resolve, 1000));
          }
        }

        if (!role) {
          throw new Error('Unable to fetch user role after multiple attempts');
        }

        await refreshUser(role);
        navigate('/');
      } catch (roleError: any) {
        console.error('Error fetching user role:', roleError);
        // If role fetch fails, sign out and clear session
        await signOut();
        localStorage.removeItem('token');
        if (roleError.message.includes('Unauthorized')) {
          setError('Session expired. Please login again.');
        } else {
          setError('Error fetching user role. Please try again.');
        }
      }
    } catch (error: any) {
      console.error('Login process error:', error);
      setError(error.message || 'An error occurred during login. Please try again.');
    }
  };

  return (
    <Container>
      <BoxContainer>
        <StyledTitle>Login</StyledTitle>
        <Quote>The face under the mask... Is that... your true face?</Quote>
        <form onSubmit={handleLogin}>
          <InputField
            label="Username"
            type="text"
            name="username"
            id="username"
            required
            value={username}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
          />
          <InputField
            label="Password"
            type="password"
            name="password"
            id="password"
            required
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
          />
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <Button type="submit" animate="fadeIn" animationDuration={1000}>Login</Button>
        </form>
      </BoxContainer>
      <Footer>© 2024 Malorian Software</Footer>
    </Container>
  );
};

export default LoginPage;
